<template>
  <div id="maintenance" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base" v-if="user">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >INFORMACIÓN DE EMPLEADO</v-row
      >
      <v-container fluid class="addForms" style="margin-top: 2% !important">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol formularios">
            <v-row>
              <div class="form-group">
                <label for="level">No. de Empleado</label>
                <input
                  type="text"
                  name="employeeNumber"
                  id="employeeNumber"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.id"
                  required
                  maxlength="20"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="name">Nombre (s)</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.nombre"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="surname">Apellido Paterno</label>
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.apellidoPaterno"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="lastName">Apellido Materno</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.apellidoMaterno"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="date">Fecha Nacimiento</label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.fechaNacimiento"
                  required
                  maxlength="20"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="curp">CURP</label>
                <input
                  type="text"
                  name="curp"
                  id="curp"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  required
                  v-model="dataUser.curp"
                  maxlength="18"
                  readonly
                />
                <p
                  style="color: red; margin-left: 160px !important"
                  v-if="errorCurp"
                >
                  *Ingresa un CURP válido
                </p>
              </div>
              <div class="form-group">
                <label for="rfc">RFC</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.rfc"
                  required
                  maxlength="13"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="imss">IMSS</label>
                <input
                  type="text"
                  name="imss"
                  id="imss"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.nss"
                  required
                  maxlength="11"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="job">Puesto</label>
                <select
                  name="job"
                  id="job"
                  v-model="dataUser.puestoId"
                  disabled
                >
                  <option disabled value="0">Selecciona Puesto</option>
                  <option
                    v-for="job in jobs"
                    :key="job.id"
                    v-bind:value="job.id"
                  >
                    {{ job.nombrePuesto }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="select2">Area/Departamento</label>
                <select
                  name="select2"
                  id="select2"
                  v-model="dataUser.areaId"
                  disabled
                >
                  <option disabled value="0">Selecciona Area/Depto</option>
                  <option
                    v-for="area in areas"
                    :key="area.id"
                    v-bind:value="area.id"
                  >
                    {{ area.nombreArea }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="select2">Sucursal</label>
                <select
                  name="select2"
                  id="select2"
                  v-model="dataUser.sucursalId"
                  disabled
                >
                  <option disabled value="0">Selecciona Sucursal</option>
                  <option
                    v-for="branch in branches"
                    :key="branch.id"
                    v-bind:value="branch.id"
                  >
                    {{ branch.nombreSucursal }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="dateAdmission">Fecha Admisión</label>
                <input
                  type="date"
                  name="dateAdmission"
                  id="dateAdmission"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.fechaAdmision"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="sueldo">Sueldo de nomina</label>
                <input
                  type="text"
                  name="sueldo"
                  id="sueldo"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.sueldo"
                  required
                  maxlength="5"
                  readonly
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-5"
            align-self="center"
          >
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresar()">
                Directorio
              </button>
              <button class="botonAmarilloDerecho" @click="continuar()">
                Continuar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="base" v-if="dataBank">
      <v-row no-gutters class="titulo ml-10 mt-5">DATOS BANCARIOS</v-row>
      <v-container fluid class="addCategory">
        <v-row no-gutters justify="end" class="formulariosTarget">
          <v-col cols="12" lg="5" md="4" sm="6" class="ml-5">
            <v-row>
              <div class="form-group mt-2">
                <label for="bank">Banco</label>
                <select
                  name="banck"
                  id="bank"
                  v-model="dataUser.datosBancoEmpleado.banco.id"
                  disabled
                >
                  <option disabled value="0">Selecciona Banco</option>
                  <option
                    v-for="bank in banks"
                    :key="bank.id"
                    v-bind:value="bank.id"
                  >
                    {{ bank.nombreCorto }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="count">Cuenta</label>
                <input
                  type="text"
                  name="count"
                  id="count"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.datosBancoEmpleado.cuenta"
                  required
                  maxlength="10"
                  readonly
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="key">Cuenta clave</label>
                <input
                  type="text"
                  name="key"
                  id="key"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dataUser.datosBancoEmpleado.cuentaClabe"
                  required
                  maxlength="18"
                  readonly
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="nomina">Anticipo de nomina</label>
                <select
                  name="nomina"
                  id="nomina"
                  v-model="dataUser.datosBancoEmpleado.anticipoNomina"
                  disabled
                >
                  <option selected disabled value="0">
                    Selecciona anticipo
                  </option>
                  <option value="1">Si</option>
                  <option value="2">No</option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresardataBank()">
                Regresar
              </button>
              <button class="botonAmarilloDerecho" @click="validacionBank()">
                Directorio
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="base" v-if="dataCfdi">
      <v-row no-gutters class="titulo ml-10 mt-5">DATOS PARA CFDI</v-row>
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formulariosTargetL"
          >
            <v-row>
              <div class="form-group">
                <label for="labor">Antigüedad</label>
                <input
                  type="text"
                  name="labor"
                  id="labor"
                  placeholder="Obligatorio"
                  class="inputs mr-2"
                  autocomplete="false"
                  v-model="dataUser.cfdiEmpleado.antiguedad"
                  required
                  maxlength="1"
                  readonly
                />Años
              </div>
              <div class="form-group">
                <label for="typeContract">Tipo de Contrato</label>
                <select
                  name="typeContract"
                  id="typeContract"
                  v-model="dataUser.cfdiEmpleado.contratoId"
                  disabled
                >
                  <option selected disabled value="0">
                    Selecciona Contrato
                  </option>
                  <option
                    v-for="contract in contracts"
                    :key="contract.id"
                    v-bind:value="contract.id"
                  >
                    {{ contract.tipoContrato }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="typeDay">Tipo de Jornada</label>
                <select
                  name="typeDay"
                  id="typeDay"
                  v-model="dataUser.cfdiEmpleado.jornadaId"
                  disabled
                >
                  <option disabled value="0">Selecciona Jornada</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.nombreJornada }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="risk">Riesgo del Puesto</label>
                <select
                  name="risk"
                  id="risk"
                  v-model="dataUser.cfdiEmpleado.riesgoId"
                  disabled
                >
                  <option disabled value="0">Selecciona Riesgo</option>
                  <option
                    v-for="risk in risks"
                    :key="risk.id"
                    v-bind:value="risk.id"
                  >
                    {{ risk.nombreRiesgo }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="union">Sindicalizado</label>
                <select
                  name="union"
                  id="union"
                  v-model="dataUser.cfdiEmpleado.sindicalizado"
                  disabled
                >
                  <option disabled value="0">Selecciona Sindicalizado</option>
                  <option selected value="1">Si</option>
                  <option value="2">No</option>
                </select>
              </div>
              <div class="form-group">
                <label for="regime">Tipo de Régimen</label>
                <select
                  name="regime"
                  id="regime"
                  v-model="dataUser.cfdiEmpleado.regimenId"
                  disabled
                >
                  <option disabled value="0">Selecciona Regimen</option>
                  <option
                    v-for="regime in regimes"
                    :key="regime.id"
                    v-bind:value="regime.id"
                  >
                    {{ regime.nombreRegimen }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label for="periodicity">Periodicidad de pago</label>
                <select
                  name="periodicity"
                  id="periodicity"
                  v-model="dataUser.cfdiEmpleado.periodicidadPagoId"
                  disabled
                >
                  <option disabled value="0">Selecciona Periodicidad</option>
                  <option
                    v-for="periodicity in periodicities"
                    :key="periodicity.id"
                    v-bind:value="periodicity.id"
                  >
                    {{ periodicity.nombrePeriodicidad }}
                  </option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="cancelar()">
                Regresar
              </button>
              <button class="botonAmarilloDerecho" @click="validacionCfdi()">
                Siguiente
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="cancel()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      jobs: [],
      areas: [],
      //Arrays para CFDI
      contracts: [],
      days: [],
      risks: [],
      regimes: [],
      periodicities: [], //Arrays para empleado
      advance: "",
      errors: [],
      error: false,
      respuesta: "",
      dataUser: [],
      user: true,
      dataBank: false,
      dataCfdi: false,
      banks: [],
      branches: [],
      titulo: "",
      confirmation: false,
      advice: false,
      errorCurp: false,
      enterprise: 0,
      branch: 0,
      auxName: "",
      auxSurname: "",
      auxLastName: "",
    };
  },
  methods: {
    listarJornadas() {
      this.show = true;
      axios
        .get(Server + "/jornadas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.days = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/riesgosPuesto", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.risks = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarBancos() {
      this.show = true;
      axios
        .get(Server + "/bancos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.banks = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarContratos() {
      this.show = true;
      axios
        .get(Server + "/contratos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.contracts = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listaRegimen() {
      this.show = true;
      axios
        .get(Server + "/regimen", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.regimes = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listaPeriodicidad() {
      this.show = true;
      axios
        .get(Server + "/periodicidadesPago", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.periodicities = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    cancelar() {
      this.dataCfdi = false;
      this.user = true;
    },
    cancel() {
      localStorage.empleado = "";
      this.$router.push("/directory");
    },
    validacionCfdi() {
      this.continuarData();
    },
    continuarData() {
      this.dataCfdi = false;
      this.dataBank = true;
    },
    validacionBank() {
      this.$router.push("/directory");
    },
    continuar() {
      this.user = false;
      this.dataBank = false;
      this.dataCfdi = true;
    },
    regresar() {
      localStorage.empleado = "";
      this.$router.push("/directory");
    },
    regresardataBank() {
      this.dataCfdi = true;
      this.dataBank = false;
    },
    recuperar() {
      this.show = true;
      axios
        .get(Server + "/empleados/" + localStorage.empleado, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.dataUser = response.data;
          localStorage.employeeNumber = "";
          this.enterprise = this.dataUser.empresaId;
          this.format_date(
            this.dataUser.fechaNacimiento,
            this.dataUser.fechaAdmision
          );
          this.obtenerAreasPuestos();
          if (this.dataUser.cfdiEmpleado.sindicalizado === false) {
            this.dataUser.cfdiEmpleado.sindicalizado = 2;
          } else {
            this.dataUser.cfdiEmpleado.sindicalizado = 1;
          }
          if (this.dataUser.datosBancoEmpleado.anticipoNomina === false) {
            this.dataUser.datosBancoEmpleado.anticipoNomina = 2;
          } else {
            this.dataUser.datosBancoEmpleado.anticipoNomina = 1;
          }
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    guardar() {
      this.show = true;
      axios
        .put(
          Server + "/empleados/" + this.dataUser.id,
          {
            EmpresaId: this.enterprise,
            Nombre: this.dataUser.nombre,
            ApellidoPaterno: this.dataUser.apellidoPaterno,
            ApellidoMaterno: this.dataUser.apellidoMaterno,
            FechaNacimiento: this.dataUser.fechaNacimiento,
            CURP: this.dataUser.curp,
            RFC: this.dataUser.rfc,
            NSS: this.dataUser.nss,
            PuestoId: this.dataUser.puestoId,
            AreaId: this.dataUser.areaId,
            SucursalId: this.dataUser.sucursalId,
            FechaAdmision: this.dataUser.fechaAdmision,
            Sueldo: parseFloat(this.dataUser.sueldo),
            //data CFDI
            Antiguedad: this.dataUser.cfdiEmpleado.antiguedad,
            ContratoId: this.dataUser.cfdiEmpleado.contratoId,
            JornadaId: this.dataUser.cfdiEmpleado.jornadaId,
            RiesgoId: this.dataUser.cfdiEmpleado.riesgoId,
            Sindicalizado:
              this.dataUser.cfdiEmpleado.sindicalizado == "1" ? true : false,
            IdRegimen: this.dataUser.cfdiEmpleado.regimenId,
            IdPeriodicidadPago: this.dataUser.cfdiEmpleado.periodicidadPagoId,
            //data Bank
            BancoId: this.dataUser.datosBancoEmpleado.banco.id, //this.bank,
            Cuenta: this.dataUser.datosBancoEmpleado.cuenta,
            CuentaClabe: this.dataUser.datosBancoEmpleado.cuentaClabe,
            AnticipoNomina:
              this.dataUser.datosBancoEmpleado.anticipoNomina == "1"
                ? true
                : false,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.enterprise = "";
          this.message = "Confirmación";
          this.respuesta = "El Empleado fue actualizado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.enterprise = "";
            this.message = "Aviso";
            this.respuesta = "El Empleado no fue actualizado.";
            this.confirmation = true;
          }
        });
    },
    aux() {
      localStorage.employeeNumber = "";
      this.$router.push("/employee");
    },
    format_date(fecha1, fecha2) {
      //console.log("fecha pasada" + fecha1);
      var aux = fecha1.substr(0, 10);
      //console.log("fecha obtenida" + aux);
      var aux2 = fecha2.substr(0, 10);
      this.dataUser.fechaNacimiento = aux;
      this.dataUser.fechaAdmision = aux2;
      return true;
    },
    obtenerAreasPuestos() {
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });

      this.jobs = [];
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });

      this.branches = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.branches = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listarJornadas();
    this.listarContratos();
    this.listaRegimen();
    this.listaPeriodicidad();
    this.recuperar();
    this.listarBancos();
  },
};
</script>